import { Component } from 'react';
import { IonInput, IonItem } from '@ionic/react';
import { FieldError, NormalText } from '../common';
import { getConfig } from '../../appConfig';
import Mobiscroll from '../../components/mobiscroll';
const { SelectOption } = Mobiscroll;
import './index.css';
import { withTranslation } from '../../lib/translate';

class MobileInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile_code: '',
      mobile_value: '',
    };
  }

  render() {
    const { __, mobile_code, mobile_value, setMobileCode, setMobileValue, className, required } = this.props;
    const countryCodeData = getConfig().country_code_data || [
      {
        "text": "GB +44",
        "value": "+44"
      }, {
        "text": "US +1",
        "value": "+1"
      }
    ];

    return (
      <>
        <IonItem lines="none" className={`mobile-input-field-wrapper input-field-wrapper inline-input-field-wrapper ${className? className : ''}`}>
          <div className='mobile-code-selection'>
            <SelectOption
              onSet={(e, val) => setMobileCode(val.getVal())}
              display="center"
              value={mobile_code}
              data={countryCodeData}
              onInit={(e, val) => {
                if (!mobile_code) {
                  setMobileCode(val._tempWheelArray[0]);
                }
              }}
            />
          </div>
          <IonInput
            className='mobile-value-input'
            onIonInput={(e) => setMobileValue(e.target.value)}
            clearInput
            required={required}
            type="tel"
            pattern="tel"
            inputmode="tel"
            placeholder="XXXXXXXXXXXX"
            value={mobile_value}
          ></IonInput>
          
        </IonItem>
        {required ? 
            (
              <FieldError className="field-error" value={mobile_value ? "":__("Required field")} />
            ) : null
          }
      </>
    );
  }
}

export default withTranslation(MobileInput);
