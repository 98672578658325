import {
  GET_TRANSACTION_HISTORY,
  SET_ORDERS_PROP,
  IMPORT_BASKET,
  LIST_PAYMENT_CARDS,
  ADD_PAYMENT_CARD,
  REMOVE_PAYMENT_CARD,
  CREATE_ORDER,
  GET_ORDER_HISTORY,
  ADD_SCANNED_CARD,
  SET_DELIVERY_ADDRESS,
  POSTCODE_CHECK,
  SET_POSTCODE_DATA,
  GET_NEAREST_LOCATION,
  LOCATION_CODE_CHECK,
  SET_LOCATION_CODE_DATA,
  ADD_PICKUP_POINT,
  SET_PICK_UP_POINT,
  ADD_DELIVERY_ADDRESS,
  REMOVE_DELIVERY_ADDRESS,
  CHECK_CANCEL_ORDER,
  STORE_ITEM_WEB,
  UPDATE_PAYMENT_INFO,
  SEND_GIFT_VOUCHER,
  REDEEM_GIFT_VOUCHER,
  CLEAR_GIFT_VOUCHER_DATA,
  GET_SENT_GIFT_VOUCHERS,
  RESEND_GIFT_VOUCHER,
  UPDATE_GIFT_VOUCHER,
  CREATE_STRIPE_ORDER,
} from './constants';
import { UPDATE_PROFILE, GET_PROFILE } from '../constants';
import { take, call, put, select } from 'redux-saga/effects';
import { loading } from '../common/sagas';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig';
import { showToast } from '../actions';
import { isDefined, forwardTo } from '../../lib/utils';
import { translateSaga } from '../common/sagas';
import LibStripe from '../../lib/stripe';
import { GET_VOUCHERS } from '../profile/constants';
import { SET_COMMON_MODAL } from '../common/constants';
import { SET_RESTAURANT_PROP } from '../restaurants/constants';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';

const isWeb = () => Capacitor.getPlatform() === 'web';

/* transaction history Saga */
export const getTransactionHistoryFlow = function* () {
  const { hasOrdering, hasLoyalty } = getConfig().appType;
  while (true) {
    yield take(GET_TRANSACTION_HISTORY);
    yield call(loading, function* () {
      // call transaction and order history
      if (hasOrdering) {
        yield call(getOrderHistoryData);
      }
      if (hasLoyalty) {
        const history = yield call(api.getHistory);
        yield put({ type: SET_ORDERS_PROP, key: 'history', value: history });
      }
    });
  }
};

export const importBasket = function* () {
  while (true) {
    yield take(IMPORT_BASKET);
    yield call(Basket.import);
  }
};

export const getPaymentCardsFlow = function* () {
  while (true) {
    yield take(LIST_PAYMENT_CARDS);
    yield call(loading, function* () {
      const cards = yield call(api.getPaymentCards);
      yield put({ type: SET_ORDERS_PROP, key: 'cards', value: cards });
    });
  }
};

export const addPaymentCardsFlow = function* () {
  while (true) {
    const action = yield take(ADD_PAYMENT_CARD);
    const { name, options } = action;
    let result = null;
    if (getConfig().payment === 'judopay') {
      options['name'] = name;
      yield call(loading, function* () {
        try {
          const savedCards = yield call(api.saveJudoPayCard, options);
          yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
          yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

          yield put({ type: GET_PROFILE });
          if (options.cb) {
            yield call(options.cb);
          }
        } catch (e) {
          yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
          throw e;
        }
      });
    } else {
      if (options.stripe) {
        result = yield call(options.stripe.createToken);
      }
      yield call(loading, function* () {
        if (options.stripe) {
          try {
            if (result.error) {
              yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
            } else {
              const payment_token = result.token.id;

              const savedCards = yield call(api.addPaymentCard, { payment_token, name });
              yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
              yield put(showToast(yield call(translateSaga, 'Card added successfully'), 'success'));

              //everytime when user add card that card will be default card. Because of that we call get profile
              yield put({ type: GET_PROFILE });

              if (options.cb) {
                yield call(options.cb);
              }
            }
          } catch (e) {
            yield put(showToast(yield call(translateSaga, 'Problem adding card'), 'warning'));
            throw e;
          }
        }
      });
    }
  }
};

export const addScannedCard = function* () {
  while (true) {
    const action = yield take(ADD_SCANNED_CARD);
    yield call(loading, function* () {
      const { options, payment_token, name } = action;
      const savedCards = yield call(api.addPaymentCard, { payment_token, name });
      yield put({ type: SET_ORDERS_PROP, key: 'cards', value: savedCards });
      yield put(showToast('Card added successfully', 'success'));

      //everytime when user add card that card will be default card. Because of that we call get profile
      yield put({ type: GET_PROFILE });
      if (options.cb) {
        yield call(options.cb);
      }
    });
  }
};

export const removePaymentCardsFlow = function* () {
  while (true) {
    const action = yield take(REMOVE_PAYMENT_CARD);
    yield call(loading, function* () {
      const { cardToken, options } = action;

      const data = {
        payment_token: cardToken,
      };
      const result = yield call(api.removePaymentCard, data);
      if (result.error) {
        yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
      } else {
        yield put({ type: SET_ORDERS_PROP, key: 'cards', value: result.data });
        yield put(showToast(yield call(translateSaga, 'Card removed successfully'), 'success'));
      }
      if (options.cb) {
        yield call(options.cb);
      }

      const store = yield select();
      if (
        store.profile &&
        store.profile.profile &&
        store.profile.profile.cardToken &&
        store.profile.profile.cardToken === cardToken
      ) {
        // If the user want to delete default card then change default card to the first one.
        // When user delete last card then put null
        yield put({
          type: UPDATE_PROFILE,
          skipAlert: true,
          data: {
            cardToken:
              store.orders.cards && store.orders.cards.length > 0 ? store.orders.cards[0].id : null,
          },
        });
      }
    });
  }
};

const checkIntentResult = function* (intentResult) {
  // LibStripe.checkIntentResult i maybe already called

  const status = isDefined(intentResult.isValid)
    ? intentResult
    : yield call(LibStripe.checkIntentResult, intentResult);
  if (status.isValid) {
    yield put(showToast(status.message, 'success'));
  } else {
    yield put(showToast(status.message, 'danger'));
  }
  return status;
};

const updatePaymentInfo = function* (intentResult, paymentType, createdOrder) {
  const paymentInfo = {
    intentResult,
    paymentMethod: paymentType,
    orderId: createdOrder.id,
  };
  yield put({ type: UPDATE_PAYMENT_INFO, paymentInfo });
};

const resetBasket = (giftVoucherData) => {
  Basket.reset();
  if (!giftVoucherData) {
    forwardTo('/order-completed', { completedOrder: true });
  } else {
  }
};
export const createStripeOrderFlow = function* () {
  yield call(Browser.removeAllListeners);
  while (true) {
    const { paymentType, paymentWebType, cb } = yield take(CREATE_STRIPE_ORDER);
    const store = yield select();
    const giftVoucherData = store.orders.giftVoucherData;
    yield call(loading, function* () {
      let orderData = Basket.parseBasketData(paymentType, paymentWebType);
      const store = yield select();
      orderData.paymentToken = store.orders.paymentToken ? store.orders.paymentToken : null;
      //create order and init stripe intent
      const result = yield call(api.createStripeOrder, orderData);
      if (giftVoucherData) {
        giftVoucherData.order_id = result.data.order.id;
      }
      // process payment intent
      if (result.data?.error || result.error) {
        if (result.data?.type === 'is_collection_time_available') {
          const restaurants = yield call(api.getRestaurants);
          yield put({
            type: SET_RESTAURANT_PROP,
            key: 'restaurants',
            value: restaurants,
          });
          yield put({
            type: SET_COMMON_MODAL,
            modal: 'deliveryTimeModalOpen',
            value: true,
          });
        } else {
          yield put(showToast('Order create error', 'warning'));
        }
      } else {
        Basket.reset();
        yield call(Browser.open, { url: result.data.checkout.url, toolbarColor: "#ffffff", presentationStyle: 'popover', windowName: '_self' });
        Browser.addListener('browserFinished', () => {
          forwardTo('/order-completed');
        });
      }
    });
  }
};
export const createOrderFlow = function* () {
  while (true) {
    const { paymentType, paymentWebType, cb } = yield take(CREATE_ORDER);
    const store = yield select();
    const giftVoucherData = store.orders.giftVoucherData;

    yield call(loading, function* () {
      let orderData = Basket.parseBasketData(paymentType, paymentWebType);
      const store = yield select();
      orderData.paymentToken = store.orders.paymentToken ? store.orders.paymentToken : null;
      //create order and init stripe intent
      let result;

      if (paymentType === 'NativePayment') {
        orderData.payment_token = 'card';
        result = yield call(api.createStripeOrderNative, orderData);
      }else{
        result = yield call(api.createOrder, orderData);
      }
      if (giftVoucherData) {
        giftVoucherData.order_id = result.data.order.id;
      }
      yield put({ type: GET_VOUCHERS });
      // process payment intent
      if (result.data?.error || result.error) {
        if (result.data?.type === 'collection-time') {
          const restaurants = yield call(api.getRestaurants);
          yield put({
            type: SET_RESTAURANT_PROP,
            key: 'restaurants',
            value: restaurants,
          });
          yield put({ type: SET_COMMON_MODAL, modal: 'deliveryTimeModalOpen', value: true });
        } else {
          yield put(showToast('Order create error', 'warning'));
        }
      } else if (paymentType === 'collectedPay') {
        const createdOrder = result.data.order;
        const store = yield select();
        yield put({
          type: SET_ORDERS_PROP,
          key: 'orderHistory',
          value: [createdOrder, ...(store.orders.orderHistory || [])],
        });
        yield call(resetBasket);
      } else {
        const createdOrder = result.data.order;
        const clientSecret = result.data.client_secret;
        if (!clientSecret || clientSecret === '') {
          yield put(showToast('Payment error.', 'danger'));
        } else {
          if (getConfig().payment === 'judopay') {
            if (clientSecret.result && clientSecret.result === 'Declined') {
              yield put(showToast('Payment error.\n' + (clientSecret.message || ''), 'danger'));
            } else {
              yield call(resetBasket, giftVoucherData);
              if (giftVoucherData) {
                yield put({
                  type: SEND_GIFT_VOUCHER,
                  data: giftVoucherData,
                });
              }
            }
          } else {
            if (clientSecret === '-1') {
              // total for order is: 0
              yield call(checkIntentResult, {
                status: 'succeeded',
              });
              yield call(resetBasket, giftVoucherData);
              if (giftVoucherData) {
                yield put({
                  type: SEND_GIFT_VOUCHER,
                  data: giftVoucherData,
                });
              }
            } else if (paymentType === 'webPayment') {
              yield put({ type: SET_ORDERS_PROP, key: 'clientSecret', value: clientSecret });
              if (cb) {
                yield call(cb, clientSecret, resetBasket, giftVoucherData);
              }
            } else {
              try {
                let intentResult;
                if (paymentType === 'google') {
                  // pay with google pay
                  intentResult = yield call(
                    LibStripe.payWithGooglePay,
                    clientSecret,
                    orderData._total,
                  );
                  yield call(updatePaymentInfo, intentResult, paymentType, createdOrder);
                  const checkedIntentResult = yield call(checkIntentResult, intentResult);
                  if (checkedIntentResult.isValid) {
                    if (giftVoucherData) {
                      yield put({
                        type: SEND_GIFT_VOUCHER,
                        data: giftVoucherData,
                      });
                    }
                    yield call(resetBasket, giftVoucherData);
                  }
                } else if (paymentType === 'apple') {
                  // pay with apple pay
                  const store = yield select();
                  intentResult = yield call(
                    LibStripe.payWithApplePay,
                    clientSecret,
                    store.profile.profile,
                  );
                  yield call(updatePaymentInfo, intentResult, paymentType, createdOrder);
                  const checkedIntentResult = yield call(checkIntentResult, intentResult);
                  yield call(resetBasket, giftVoucherData);

                  if (giftVoucherData && checkedIntentResult.isValid) {
                    yield put({
                      type: SEND_GIFT_VOUCHER,
                      data: giftVoucherData,
                    });
                  }
                }else if (paymentType === 'NativePayment') {
                  const store = yield select();
                  const uuid = `${store?.profile?.profile?.id}_${Date.now()}`;
                  try{
                    const nativePayment = getConfig().nativePayment || {};
                    const merchantDisplayName = nativePayment.merchantDisplayName ||  "";
                    const merchantCountryCode = nativePayment.merchantCountryCode || 'GB';
                    const withZipCode = nativePayment.withZipCode || false;
                    const enableGooglePay = nativePayment.enableGooglePay || false;
                    const googlePayIsTesting = nativePayment.googlePayIsTesting || false;
                    const enableApplePay = nativePayment.enableApplePay || false;
                    const applePayMerchantId = nativePayment.applePayMerchantId || ''
                    const stripeInstance = LibStripe.getStripeInstance();
                    const customerId =  result.data?.customer_id;
                    const customerEphemeralKeySecret = result.data?.ephemeral_key;
                    yield stripeInstance.createPaymentSheet({
                      GooglePayIsTesting: googlePayIsTesting,
                      paymentIntentClientSecret: clientSecret,
                      merchantDisplayName,
                      merchantCountryCode,
                      withZipCode,
                      enableGooglePay,
                      enableApplePay,
                      applePayMerchantId,
                      customerId,
                      customerEphemeralKeySecret
                    });
                    const { paymentResult } = yield stripeInstance.presentPaymentSheet();
                    if(paymentResult === 'paymentSheetCompleted'){
                      yield call(resetBasket, giftVoucherData);
                      if (giftVoucherData) {
                        yield put({
                          type: SEND_GIFT_VOUCHER,
                          data: giftVoucherData,
                        });
                      }
                    }else{
		                  Basket.setUUID(uuid);
                    }
                  }catch(ex){
                    console.log('Error ',ex);
		                Basket.setUUID(uuid);
                  }
                  
                }  else {
                  // pay with regular payment card
                  let intentResult = null;
                  if(isWeb()){
                    intentResult = yield call(LibStripe.confirmPaymentIntent, clientSecret, {
                      paymentMethodId: orderData.payment_token,
                    });
                    const checkedIntentResult = yield call(checkIntentResult, intentResult);
                    if (checkedIntentResult.isValid) yield call(resetBasket, giftVoucherData);
                    if (giftVoucherData && checkedIntentResult.isValid) {
                      yield put({
                        type: SEND_GIFT_VOUCHER,
                        data: giftVoucherData,
                      });
                    }
                  }else {
                    intentResult = yield call(api.confirmPaymentIntentNative, {intent_id: result?.data?.intent_id,payment_token: orderData.payment_token })
                    if(intentResult && intentResult?.data?.confirmedPaymentIntent?.next_action?.use_stripe_sdk?.stripe_js){
                      yield call(Browser.open, {
                        url:intentResult?.data?.confirmedPaymentIntent?.next_action?.use_stripe_sdk?.stripe_js, 
                        toolbarColor: "#ffffff", 
                        presentationStyle: 'popover', 
                        windowName: '_self'
                      });
                      Browser.addListener('browserFinished', () => {
                        forwardTo('/order-completed');
                      });
                    }
                  }
                }
                // yield call(resetBasket);
                // if (isDefined(intentResult) && intentResult.isValid) {
                //   createdOrder.amount_paid = intentResult.intentResult.amount;
                // }
              } catch (e) {
                yield put(showToast('Payment error.\n' + (e.message || ''), 'danger'));
              }
            }
          }
        }

        //update ordr history with new added order
        const store = yield select();
        createdOrder.order_value = orderData.total;
        yield put({
          type: SET_ORDERS_PROP,
          key: 'orderHistory',
          value: [createdOrder, ...(store.orders.orderHistory || [])],
        });
      }
    });
  }
};

export const updatePaymentInfoFlow = function* () {
  while (true) {
    const action = yield take(UPDATE_PAYMENT_INFO);
    yield call(loading, function* () {
      const { paymentInfo } = action;
      const updatedOrder = yield call(api.updatePaymentInfo, paymentInfo);
      const store = yield select();
      let orderHistory = store.orders.orderHistory;
      const index = orderHistory.findIndex((i) => i.id === updatedOrder.id);
      if (index !== -1) {
        orderHistory[index] = updatedOrder;
      } else {
        orderHistory = [updatedOrder, ...orderHistory];
      }
      yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
    });
  }
};

const getOrderHistoryData = function* () {
  const orderHistory = yield call(api.getOrderHistory);
  yield put({ type: SET_ORDERS_PROP, key: 'orderHistory', value: orderHistory });
};

export const getOrderHistoryFlow = function* () {
  while (true) {
    const action = yield take(GET_ORDER_HISTORY);
    const loading = isDefined(action.loading) ? action.loading : true;

    if (loading) {
      yield call(loading, function* () {
        yield call(getOrderHistoryData);
      });
    } else {
      yield call(getOrderHistoryData);
    }
  }
};

export const addDeliveryAddressFlow = function* () {
  while (true) {
    const action = yield take(ADD_DELIVERY_ADDRESS);
    const { deliveryAddress, flag } = action;
    yield call(loading, function* () {
      try {
        const address = yield call(api.addDeliveryAdress, deliveryAddress);
        yield put({ type: SET_DELIVERY_ADDRESS, deliveryAddress: JSON.parse(address.config.data) });
        yield put(showToast(yield call(translateSaga, 'Address added successfully'), 'success'));
        yield put({ type: GET_PROFILE });
        if (!flag) {
          yield call(forwardTo, '/delivery-time');
        }
      } catch (e) {
        yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
      }
    });
  }
};

export const postCodeCheckFlow = function* () {
  while (true) {
    const action = yield take(POSTCODE_CHECK);
    const { postcode } = action;
    yield call(loading, function* () {
      try {
        const checkedCodeData = yield call(api.postCodeCheck, { postcode });
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
        if (checkedCodeData.data.length < 1) {
          yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
        }
      } catch (e) {
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
      }
    });
  }
};

export const getNearestLocationFlow = function* () {
  while (true) {
    const action = yield take(GET_NEAREST_LOCATION);
    const { postcode, charter_delivery } = action;
    yield call(loading, function* () {
      try {
        const checkedCodeData = yield call(api.getNearestLocation, { postcode, charter_delivery });
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData });
        if (checkedCodeData.data.length < 1) {
          //yield put(showToast(yield call(translateSaga, 'No location found'), 'warning'));
        }
      } catch (e) {
        yield put({ type: SET_POSTCODE_DATA, checkedCodeData: { data: {} } });
      }
    });
  }
};


export const locationCodeCheckFlow = function* () {
  while (true) {
    const action = yield take(LOCATION_CODE_CHECK);
    const { locationCode } = action;
    try {
      const checkedLocationCodeData = yield call(api.locationCodeCheck, {
        location_code: locationCode,
      });
      yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData });
    } catch (e) {
      yield put({ type: SET_LOCATION_CODE_DATA, checkedLocationCodeData: { data: [] } });
    }
  }
};

export const addPickupPointFlow = function* () {
  while (true) {
    const action = yield take(ADD_PICKUP_POINT);
    const { pickUpPoint, code } = action;
    yield call(loading, function* () {
      try {
        const point = yield call(api.addPickupPoint, { restaurant_id: pickUpPoint, code });
        let parsedData = JSON.parse(point.config.data);
        yield put({ type: SET_PICK_UP_POINT, pickUpPoint: parsedData.code });
        yield put(
          showToast(yield call(translateSaga, 'Pickup point added successfully'), 'success'),
        );
        yield put({ type: GET_PROFILE });
        yield call(forwardTo, '/delivery-time');
      } catch (e) {
        yield put(showToast('Add address.\n' + (e.message || ''), 'danger'));
      }
    });
  }
};

export const removeDeliveryAddressFlow = function* () {
  while (true) {
    const action = yield take(REMOVE_DELIVERY_ADDRESS);
    yield put({ type: SET_ORDERS_PROP, key: 'removeAddressModal', value: false });
    yield call(loading, function* () {
      const { index, order_type } = action;
      const data = {
        id: index,
        orderType: order_type,
      };
      const result = yield call(api.removeDeliveryAddress, data);
      if (result.error) {
        yield put(showToast(yield call(translateSaga, result.error.message), 'warning'));
      } else {
        yield put(
          showToast(yield call(translateSaga, 'Delivery address removed successfully'), 'success'),
        );
      }
      yield put({ type: GET_PROFILE });
    });
  }
};

export const checkCancelOrderFlow = function* () {
  while (true) {
    const action = yield take(CHECK_CANCEL_ORDER);
    yield put({ type: SET_ORDERS_PROP, key: 'cancelOrderModal', value: false });
    yield call(loading, function* () {
      const { orderId, restaurantId } = action;
      const data = {
        order_id: orderId,
        restaurant_id: restaurantId,
      };
      yield call(api.cancelOrder, data);
      yield put(showToast(yield call(translateSaga, 'Order successfully refunded'), 'success'));
    });
  }
};

export const storeWebItemFlow = function* () {
  while (true) {
    const action = yield take(STORE_ITEM_WEB);
    yield put({ type: SET_ORDERS_PROP, key: 'storedItemWeb', value: action.item });

    if (action.cb) {
      yield call(action.cb);
    }
  }
};
export const sendGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(SEND_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.sendGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/gift-voucher', { voucher: action.data, voucherCreated: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const resendGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(RESEND_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.resendGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/sent-vouchers', { giftVoucherRedeemed: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const updateGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(UPDATE_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.updateGiftVoucher, action.data);
			if (!result.error) {
				forwardTo('/sent-vouchers', { giftVoucherUpdated: true });
				yield put({ type: CLEAR_GIFT_VOUCHER_DATA });
				yield put({ type: GET_SENT_GIFT_VOUCHERS });
			}
		});
	}
};
export const redeemGiftVoucherFlow = function* () {
	while (true) {
		const action = yield take(REDEEM_GIFT_VOUCHER);
		yield call(loading, function* () {
			const result = yield call(api.redeemGiftVoucher, action.data);
			if (!result.error) {
				// forwardTo('/gift-vouchers', { giftVoucherRedeemed: true });
				yield put({
					type: 'SET_REDEEMED_GIFT_VOUCHER',
					value: result.data,
				});
			}
		});
	}
};
export const getSentGiftVouchersFlow = function* () {
	while (true) {
		yield take(GET_SENT_GIFT_VOUCHERS);
		yield call(loading, function* () {
			let result = yield call(api.getSentGiftVouchers);
			result.sort((a, b) => b.id - a.id);
			yield put({
				type: 'SET_SENT_GIFT_VOUCHERS',
				value: result,
			});
		});
	}
};