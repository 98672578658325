import React from 'react';
import { connect } from 'react-redux';
import {
  IonButton,
  IonTextarea,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonRadioGroup,
  IonRadio,
  IonAlert,
} from '@ionic/react';
import { withRouter } from 'react-router';
import { starOutline, star } from 'ionicons/icons';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { getRestaurants, logout, sendFeedback, setCommonModal } from '../../store/actions';
import {
  FieldError,
  Title,
  StrongText,
  SmallText,
  Spacer,
  NormalText,
} from '../../components/common';
import { validateForm, isDefined, isWebConfig, goBack, forwardTo } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Mobiscroll from '../../components/mobiscroll';
import headerImage from '../../assets/images/icons/tech-header-icon.png'

const { SelectOption } = Mobiscroll;

class TechSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.resetState(),
    };
    this.formConfig = {
      food: { type: 'integer', required: true, invalidValue: 0 },
      service: { type: 'integer', required: true, invalidValue: 0 },
      selectedRestaurant: { type: 'select', required: true, invalidValue: null },
    };
  }

  resetState = () => ({
    food: 0,
    service: 0,
    customerService: false,
    techSupport: true,
    commentService: '',
    commentTech: '',
    radioGroup: 'customerService',
    selectedRestaurant: null,
    formErrors: {},
    orderTypeFeedback: '',
  });

  handleFeedback = () => {
    let formErrors;
    if (this.state.customerService) {
      formErrors = validateForm(this.formConfig, this.state);
    } else {
      formErrors = [];
    }

    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      const {
        orderTypeFeedback,
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
      } = this.state;
      const feedback = {
        food,
        service,
        commentService,
        commentTech,
        selectedRestaurant,
        techSupport,
        customerService,
        orderTypeFeedback,
      };
      this.props.dispatch(sendFeedback(feedback));
      // reset state
      this.setState({ ...this.resetState() });
    }
  };
  selectRestaurant = (selectedRestaurantId) => {
    this.setState({ selectedRestaurant: selectedRestaurantId });
    if (selectedRestaurantId !== null) {
      let formErrors = { ...this.state.formErrors };
      formErrors.selectedRestaurant = null;
      this.setState({ formErrors });
    }
  };

  setValue(field, value) {
    if (['customerService', 'techSupport'].indexOf(field) !== -1) {
      const decheckedKey = field === 'customerService' ? 'techSupport' : 'customerService';
      this.setState({ [field]: value, [decheckedKey]: false });
    } else {
      this.setState({ [field]: value });
    }
  }

  formatDataForSelect = (stores) => {
    const { __ } = this.props;
    let arrForSelect = [];
    stores.forEach((store) => {
      if (isDefined(store.is_published)) {
        if (store.is_published) {
          arrForSelect.push({ text: store.name, value: store.id });
        }
      }
    });
    return [{ text: __('Select Shop'), value: null }, ...arrForSelect];
  };

  handleChange = (event) => {
    this.setValue(event.detail.value, true);
    this.setState({
      radioGroup: event.detail.value,
    });
  };
  backHandler = () => {
    if (this.props.location?.state?.fromBillPay) {
      forwardTo('/table-bill-pay');
    } else {
      goBack();
    }
  };
  componentDidMount() {
    const { restaurants } = this.props;
    if (!restaurants || restaurants.length === 0) {
      this.props.dispatch(getRestaurants());
    }
    const selectedRestaurant = this.props.location?.state?.selectedRestaurant;
    if (selectedRestaurant) {
      const selectedRestaurantId = restaurants.find(
        (restaurant) => restaurant.pos_location_id == selectedRestaurant,
      )?.id;
      this.selectRestaurant(selectedRestaurantId);
      this.setState({ customerService: false, techSupport: true, radioGroup: 'techSupport' });
      if (this.props.location?.state?.fromBillPay) {
        this.setState({ orderTypeFeedback: 'bill_pay' });
      }
    }
  }
  render() {
    const { __, isFeedbackModalOpen, restaurants } = this.props;
    const { techSupport, commentService, commentTech, formErrors } = this.state;
    const stores = restaurants.sort((a, b) => {
      if (isDefined(a.name) && isDefined(b.name)) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
      return 0;
    });

    return (
      <Loading transparent>
        <Layout
          backHandler={this.backHandler}
          color="transparent"
          title={__('Leave feedback')}
        >
          <div className="absolute-content scrollable-y">
          <img style={{height:'40px'}} src={headerImage}/>
            <Title >{__('Tech support')}</Title>
              <div className="">
                <div className="feedback-box-wrapper input-field-container">
                  <NormalText>{__('Comments')}:</NormalText>
                  <div className="input-field-wrapper">
                    <IonTextarea
                      className="thiner-text"
                      value={commentTech}
                      onIonChange={(event) => this.setValue('commentTech', event.detail.value)}
                    ></IonTextarea>
                  </div>
                </div>
              </div>
     
            <Spacer />
            <IonButton expand="block" color="primary" onClick={this.handleFeedback}>
              {__('Submit')}
            </IonButton>
            {this.props.profile.is_guest && (
              <IonButton
                expand="block"
                color="primary"
                fill="outline"
                onClick={() => this.props.dispatch(logout())}
              >
                {__('Go back')}
              </IonButton>
            )}
          </div>
        </Layout>
        <IonAlert
          isOpen={isFeedbackModalOpen}
          onDidDismiss={() => this.props.dispatch(setCommonModal('isFeedbackModalOpen', false))}
          header={__('Thank you')}
          message={__('We really appreciate you taking the time to let us know how we did.')}
          buttons={[
            {
              text: __('OK'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                this.props.dispatch(setCommonModal(('isFeedbackModalOpen', false)));
                this.setState({
                  food: 0,
                  service: 0,
                  customerService: true,
                  techSupport: false,
                  commentService: '',
                  commentTech: '',
                });
              },
            },
          ]}
        />
      </Loading>
    );
  }
}
const stateToProps = (store) => {
  const { profile } = store.profile;
  const { appVersion, isFeedbackModalOpen } = store.common;
  const { restaurants } = store.restaurants;
  return {
    appVersion,
    isFeedbackModalOpen,
    restaurants: restaurants || [],
    profile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(TechSupport)));
