import { IonButton, IonCard, IonCardContent, IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { NormalText, SmallText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute, getSingleDeliveryOption } from '../../lib/utils';
import { loading, setDeliveryOption } from '../../store/actions';
import { CLEAR_GIFT_VOUCHER_DATA } from '../../store/constants';
import './index.css';

class DeliveryOptions extends React.Component {
	constructor() {
		super();
		this.state = {
			delivery: [],
			option: null,
		};
	}
	backHandler = () => {
		const defaultRoute = getDefaultRoute(this.props.navConfig);
		forwardTo(defaultRoute.path);
	};
	selectOption(option) {
		this.setState({ option: option });
	}
	setDeliveryOption = () => {
		Basket.reset();
		this.props.dispatch({ type: CLEAR_GIFT_VOUCHER_DATA });
		const { option } = this.state;
		this.props.dispatch(setDeliveryOption(option));
		Basket.setDeliveryOption(option);
		Basket.setOrderType(option.id);
		forwardTo(option.route, { isLogoClicked: false });
	};

	checkForSingleDeliveryOption = async () => {
		const singleDeliveryOption = await getSingleDeliveryOption();
		if (singleDeliveryOption) {
			this.setDeliveryOption(singleDeliveryOption);
		}
	};

	getOrderingButtons = async () => {
		this.props.dispatch(loading(true));
		const config = await api.getFrontEndAppConfig();
		this.setState({ delivery: config.front_end_app_config.delivery });
		this.props.dispatch(loading(false));
	};

	componentDidMount() {
		const { defaultMenuId } = this.props;
		Basket.reset();
		this.checkForSingleDeliveryOption();
		this.getOrderingButtons();
		api.getDefaultMenu(defaultMenuId).then((res) => {
			this.props.dispatch({ type: 'SET_RESTAURANT_PROP', key: 'defaultMenu', value: res });
		});
		
		const delivery = getConfig().delivery.filter(d=>!d.isRemoved && d.id !== 'browse-menu')
		if(delivery.length==1){
			forwardTo(delivery[0].route)
		}
	}

	componentWillUnmount() {
		this.props.dispatch(loading(false));
	}

	componentDidUpdate() {
		this.checkForSingleDeliveryOption();
	}

	render() {
		const { __ } = this.props;
		const { option } = this.state;
		return (
			<Loading>
				<Layout showHamburger backHandler={this.backHandler} headerWithTitle={true} title={__('Start new order')} color="transparent">
					<IonCard className="delivery-options-card">
						<Title className="web-only">{__('Start new order')}</Title>
						<IonCardContent className="delivery-options-content">
							<IonList lines="none" className="box-wrapper" style={{ paddingTop: 0, paddingBottom: 0 }}>
								{getConfig().delivery.map((d, index) => (
									<div key={index}>
										{!d.isRemoved && d.id !== 'browse-menu' && (
											<IonItem key={`option${index}`} disabled={d.isDisabled} onClick={() => this.selectOption(d)}>
												<IonLabel>
													<NormalText className="block">{__(d.label)}</NormalText>
													<SmallText className="order-description">{__(d.description)}</SmallText>
												</IonLabel>
												<IonCheckbox checked={option && d.id === option.id} slot="start" color="primary" />
											</IonItem>
										)}
									</div>
								))}
							</IonList>
						</IonCardContent>
						<Spacer />
						<div className="delivery-options-button">
							<IonButton disabled={!option} expand="block" color="primary" className="uppercase" onClick={() => this.setDeliveryOption()}>
								{__('Start new order')}
							</IonButton>
						</div>
					</IonCard>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => ({
	deliveryOption: state.orders.deliveryOption,
	defaultMenuId: state.common.defaultMenuId,
	navConfig: state.common.navConfig,
});

export default connect(stateToProps)(withTranslation(DeliveryOptions));

