import { IonButton, IonInput, IonItem } from '@ionic/react';
import { withTranslation } from '../../lib/translate.js';
import { sendVoucherCode } from '../../store/actions.js';
import { connect } from 'react-redux';
import { Component } from 'react';
import './index.css';


class VoucherCodeInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      voucherCode: '',
      voucherCodeVisible: false
    }
  }
  handlerOfVoucherCode = (e) => {
    const val = e.target.value.toUpperCase();
    this.setState({ voucherCode: val });
  };
  sendCode = () => {
    const { dispatch } = this.props;
    const { voucherCode } = this.state;
    const data = { code: voucherCode.toUpperCase() };
    this.setState({ voucherCode: '' });
    dispatch(sendVoucherCode(data, false,this.props.autoApplyVoucher));
  };

  render() {
    const { __, className, children, useCustomButton, buttonText } = this.props;
    const { voucherCodeVisible, voucherCode } = this.state;
    return (
      <>
        {voucherCodeVisible ? (
          <div className={className || "apply-voucher-code-wrapper"}>
            <div className="input-field-container">
              <IonItem className="input-field-wrapper" lines="none">
                <IonInput
                  placeholder={__('Voucher Code')}
                  onIonInput={(e) => this.handlerOfVoucherCode(e)}
                  value={voucherCode}
                ></IonInput>
              </IonItem>
            </div>
            <IonButton
              disabled={voucherCode === ''}
              color="primary"
              expand="block"
              onClick={this.sendCode}
              className="submit-button"
            >
              {__('Submit')}
            </IonButton>
          </div>
        ) : useCustomButton ?
          (
            <div onClick={() => this.setState({ voucherCodeVisible: true })}>
              {children}
            </div>
          ) : (
            <div className="redeem-voucher-btn">
              <IonButton
                onClick={() => {
                  this.setState({ voucherCodeVisible: true });
                }}
                color="black"
                fill="outline"
                className='bold'
              >
                {__(buttonText) || __('Apply voucher code')}
              </IonButton>
            </div>
          )}
      </>
    );
  }
}

export default connect()(withTranslation(VoucherCodeInput));