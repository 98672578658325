import React from 'react';
import { withTranslation } from '../../lib/translate';
import Layout from '../../components/layout';
import { connect } from 'react-redux';
import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import './index.css';
import { forwardTo, goBack } from '../../lib/utils';
import { registerRequest, setFullRegisterForm, setGuestUser } from '../../store/actions';
import Loading from '../../components/spinner';
import { Capacitor } from '@capacitor/core';
import { CREATE_ORDER } from '../../store/constants';
import { getConfig } from '../../appConfig';

const isWeb = () => Capacitor.getPlatform() === 'web';
class GiftVoucherOverview extends React.Component {
	resendGiftVoucher = () => {
		const voucher = this.props?.location.state?.voucher || Basket.gift_voucher_data;
		const { dispatch } = this.props;
		const data = {
			gift_id: voucher.id,
			recipient_email: voucher.recipient_email,
			personal_message: voucher.personal_message,
			send_on_date: voucher.send_on_date,
		};
		dispatch({
			type: 'RESEND_GIFT_VOUCHER',
			data,
		});
	};
	handleVoucherPay = () => {
		const voucher = this.props?.location.state?.voucher;
		if (this.props.auth.loggedIn) {
			if(!isWeb() && getConfig()?.nativePayment?.isVisible){
				this.props.dispatch({ type: CREATE_ORDER, paymentType: 'NativePayment' });
			}else{
				forwardTo('/checkout', { giftVoucher: voucher });
			}
		} else {
			const timestamp = new Date().getTime();

			const guestUserData = {
				guest_email: `${timestamp}_${voucher.sender_email}`,
				guest_first_name: voucher.sender_name,
				guest_accepted_terms_and_conditions: true,
				guest_mobile: '',
			};

			this.handleGuestReigister(guestUserData);
		}
	};
	handleGuestReigister(guestUserData) {
		const { registerFormData } = this.props;
		const timestamp = new Date().getTime();
		const { guest_first_name, guest_mobile, guest_accepted_terms_and_conditions } = guestUserData;
		let guest_email = guestUserData.guest_email == '' || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(guestUserData.guest_email) ? 'guest@5loyalty.com' : guestUserData.guest_email;
		registerFormData.email = `${timestamp}_${guest_email}`;
		registerFormData.first_name = guest_first_name;
		registerFormData.mobile = guest_mobile;
		registerFormData.accepted_terms_and_conditions = guest_accepted_terms_and_conditions;
		registerFormData.guest = true;
		registerFormData.password = timestamp;
		this.props.dispatch(registerRequest({ referrer: '/checkout' }));
	}
	backHandler(voucher) {
		if (this.props.location && this.props.location.state && this.props.location.state.redirectedFromCheckout) {
			forwardTo('/gift-vouchers', { voucher: voucher, isSent: this.props?.location?.state?.isSent });
		} else {
			goBack();
		}
	};
	render() {
		const { __ } = this.props;
		const voucher = this.props?.location.state?.voucher || Basket.gift_voucher_data;
		return (
			<Loading>
				<Layout headerWithTitle title={__('Your voucher')} color="transparent" backHandler={() => this.backHandler(voucher)} >
					<div className="absolute-content scrollable-y">
						<Title className="web-only">{__('Your voucher')}</Title>

						{this.props.location?.state?.voucherCreated && (
							<>
								<div className="box-content gift-voucher-success">
									<IonIcon icon={checkmarkCircleOutline}></IonIcon>
									<div>
										<StrongText className="block">{__('Gift voucher created successfully')}</StrongText>
										<NormalText>{`${__('The voucher will be sent on')} ${moment(voucher.send_on_date).format('DD/MM/yyyy')}`}</NormalText>
									</div>
								</div>
								<Spacer />
							</>
						)}
						<div className="box-content ">
							<div className="gift-voucher-order-content">
								<div className="gift-voucher-order-content-summary">
									<StrongText className="primary-color">
										{Basket.formatPrice(voucher.points_value / 100)} {__('gift voucher')}
									</StrongText>
									<SmallText className="block">
										{__('Recipient')}
										{': '}
										{voucher.recipient_email}
									</SmallText>
									<SmallText className="block">
										{__('Send date')}
										{': '}
										{moment(voucher.send_on_date).format('Do MMM yyyy')}
									</SmallText>
									<SmallText className="block">
										{__('Expiry date')}
										{': '}
										{moment(voucher.send_on_date).add(1, 'year').format('Do MMM yyyy')}
									</SmallText>
								</div>
								{this.props?.location?.state?.hasEdit && (
									<div
										className="underlined pointer"
										onClick={() => {
											forwardTo('/gift-vouchers', { voucher, isSent: this.props?.location?.state?.isSent });
										}}
									>
										{__('Edit')}
									</div>
								)}
							</div>
							{voucher.personal_message && (
								<div>
									<Spacer />
									<StrongText className="primary-color block">{__('Personal message')}</StrongText>
									<NormalText>{voucher.personal_message}</NormalText>
								</div>
							)}
						</div>
						<Spacer />

						<IonGrid className="box-content order-content-wrapper paddL">
							<IonRow>
								<IonCol>{__('1x eGift voucher')}</IonCol>
								<IonCol className="righted ">{Basket.formatPrice(voucher.points_value / 100)}</IonCol>
							</IonRow>
							<IonRow>
								<IonCol>{this.props.location?.state?.voucherCreated ? __('Total paid') : __('Total to pay')}</IonCol>
								<IonCol className="righted ">{Basket.formatPrice(voucher.points_value / 100)}</IonCol>
							</IonRow>
						</IonGrid>
						<Spacer />
						{this.props.location?.state?.voucherCreated && this.props.profile.is_guest && (
							<>
								<IonButton className="link underlined" expand="block">
									{__('Done')}
								</IonButton>
								<Spacer />
							</>
						)}
						{!this.props.location?.state?.voucherCreated && (
							<>
								{this.props.location.state?.isSent ? (
									<>
										{!voucher.redeemed_on && voucher.is_sent ? (
											<>
												<IonButton color="primary" onclick={() => this.resendGiftVoucher()} expand="block">
													{__('Resend')}
												</IonButton>
											</>
										) : (
											<></>
										)}
									</>
								) : (
									<IonButton color="primary" onclick={() => this.handleVoucherPay()} expand="block">{`${__('Pay')} ${Basket.formatPrice(voucher.points_value / 100)}`}</IonButton>
								)}
							</>
						)}

						{!this.props.auth.loggedIn && (
							<>
								<Spacer />

								<NormalText className="block">
									{__('Why not register an account now before you pay? This will allow you to view your sent vouchers and resend them if you need to.')}
								</NormalText>
								<IonButton className="link underlined" expand="block" onclick={() => forwardTo('/register')}>
									{__('Register your account')}
								</IonButton>
							</>
						)}
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	return {
		auth: state.profile.auth,
		registerFormData: state.profile.registerFormData,
		profile: state.profile.profile,
	};
};
export default connect(stateToProps)(withTranslation(GiftVoucherOverview));
