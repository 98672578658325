import {
  IonAlert,
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
} from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import PasswordInput from '../../components/passwordInput';
import Loading from '../../components/spinner';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, getDefaultRoute, isWebConfig, validateForm } from '../../lib/utils';
import {
  registerRequest,
  setFullRegisterForm,
  setModal,
  setRegisterForm,
  setSysLocale,
  updateProfile,
} from '../../store/actions';
import { CLEAR_REGISTER_FORM } from '../../store/constants';
import SocialLogin from '../../components/SocialLogin';
import './index.css';
import { Device } from '@capacitor/device';

const { DatePicker } = Mobiscroll;

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {},
      fromGiftVoucher: this.props?.location?.state?.fromGiftVoucher,
    };
    this.handleInput = this.handleInput.bind(this);
    this.handleRegister = this.handleRegister.bind(this);
    this.navToTerms = this.navToTerms.bind(this);
    this.formConfig = {
      first_name: { type: 'text', required: true },
      last_name: { type: 'text', required: true },
      mobile: { type: 'text', required: false },
      email: { type: 'email', required: true },
      password: { type: 'password', required: true },
      accepted_terms_and_conditions: { type: 'toggle', toggle: true },
      birthday: {
        type: 'birthday',
        required: getConfig().restrictions?.min_registration_age ? true : false,
      },
    };
  }

  handleInput(key, val) {
    const { dispatch } = this.props;
    dispatch(setRegisterForm(key, val));
    if (key === 'accepted_terms_and_conditions') {
      let formErrors = { ...this.state.formErrors };
      formErrors.accepted_terms_and_conditions = undefined;
      this.setState({ formErrors });
    }
  }

  handleRegister() {
    const { registerFormData } = this.props;
    let formErrors = validateForm(this.formConfig, registerFormData);
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      this.props.dispatch(registerRequest({ referrer: '/register' }));
    }
  }

  componentDidMount() {
    const { loggedIn } = this.props.auth;
    if (!this.props.location?.state?.saveRegisterData) {
      this.props.dispatch({
        type: CLEAR_REGISTER_FORM,
      });
    }

    Device.getLanguageCode().then((res) => {
      const sysLocale = res.value.substr(0, 2);
      if (sysLocale) {
        this.props.dispatch(setSysLocale(sysLocale));
      }
    });
    if (loggedIn) {
      const defaultRoute = getDefaultRoute(this.props.navConfig);
      forwardTo(defaultRoute.path, {
        fromLogin: this.props?.location?.state?.fromGiftVoucher ? true : false,
      });
    }
  }

  returnToLogin = (history) => history.goBack();

  navToTerms = () => forwardTo('/terms');

  validateSocialForm() {
    const { registerFormData } = this.props;
    const formErrors = validateForm(
      {
        ...this.formConfig,
        first_name: { type: 'first_name', required: false },
				last_name: { type: 'last_name', required: false },
        password: { type: 'password', required: false },
        email: { type: 'email', required: false },
      },
      registerFormData,
    );
    this.setState({ formErrors });
    if (Object.keys(formErrors).length === 0) {
      return true;
    }
    return false;
  }

  render() {
    const { __, isRegisterModalOpen, registerFormData, dispatch } = this.props;
    const email = registerFormData.email;
    const password = registerFormData.password;
    const first_name = registerFormData.first_name;
    const last_name = registerFormData.last_name;
    const mobile = registerFormData.mobile;
    const accepted_terms_and_conditions = registerFormData.accepted_terms_and_conditions;
    const is_subscribed = registerFormData.is_subscribed;
    const birthday = registerFormData.birthday;
    const { loggedIn } = this.props.auth;
    const hasDOB = true;
    const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
    const defaultDateValue = moment().subtract(18, 'years');
    let hasSocialLogin = getConfig()?.flags && getConfig().flags.hasSocialLogin;
    const { hideSocialLoginNative } = getConfig()?.socialLogin || {};
    if(!isWebConfig() && hideSocialLoginNative){
      hasSocialLogin = false
    }
    return (
      <Loading>
        <Layout headerTitle="Register" color="transparent">
          {!loggedIn ? (
            <div className="absolute-content scrollable-y">
              <Title>{__('Sign up with email')}</Title>
              <NormalText className="block">
                {__(
                  'Create an account to earn and redeem loyalty, and access exclusive vouchers and offers.',
                )}
              </NormalText>
              <Spacer size={1} />
              {this.state.registerWithEmail || !hasSocialLogin ? (
                <>
                  <IonList>
                    <IonItem lines="none" className="input-field-wrapper">
                      <IonInput
                        placeholder={__('Email address') + ' *'}
                        onIonInput={(e) => this.handleInput('email', e.target.value)}
                        onIonBlur={(e) => {
                          const usernameInput = document.querySelector(
                            'input[type="email"]:-webkit-autofill',
                          );
                          if (usernameInput) {
                            this.handleInput('email', usernameInput.value);
                          }
                          this.handleInput('email', e.target.value);
                        }}
                        type="email"
                        pattern="email"
                        inputmode="email"
                        value={email}
                      ></IonInput>
                    </IonItem>
                    <FieldError className="field-error" value={__(this.state.formErrors.email)} />
                    <IonItem lines="none" className="input-field-wrapper">
                      <PasswordInput
                        placeholder={__('Password') + ' *'}
                        onIonChange={(e) => this.handleInput('password', e.target.value)}
                        value={password}
                      />
                    </IonItem>
                    <FieldError
                      className="field-error"
                      value={__(this.state.formErrors.password)}
                    />
                    <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('First name') + ' *'}
                    onIonInput={(e) => this.handleInput('first_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={first_name}
                  ></IonInput>
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.first_name)} />

                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Last name') + ' *'}
                    onIonInput={(e) => this.handleInput('last_name', e.target.value)}
                    required={true}
                    type="text"
                    pattern="text"
                    inputmode="text"
                    value={last_name}
                  ></IonInput>
                </IonItem>
                <FieldError className="field-error" value={__(this.state.formErrors.last_name)} />

                    {getConfig().restrictions?.min_registration_age ? (
                      <>
                        <IonItem lines="none" className="input-field-wrapper">
                          <DatePicker
                            placeholder="Enter your day of birth"
                            className="data-picker-input"
                            display="bottom"
                            max={yesterday}
                            defaultValue={defaultDateValue}
                            setText={__('Done')}
                            cancelText={__('Cancel')}
                            value={birthday}
                            onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                            dateFormat="dd/mm/yy"
                          />
                        </IonItem>
                        <FieldError
                          className="field-error"
                          value={__(this.state.formErrors.birthday)}
                        />
                      </>
                    ) : null}
                  </IonList>
                  <Spacer size={1} />
                  <div className="no-padding">
                    <IonList>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <StrongText>{__('Terms & conditions')}</StrongText>
                            <Spacer size={1} />
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="primary"
                                checked={accepted_terms_and_conditions}
                                onIonChange={(e) =>
                                  this.handleInput(
                                    'accepted_terms_and_conditions',
                                    e.detail.checked,
                                  )
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__('By signing up you agree to') + ' '}{' '}
                                  <span
                                    className="pointer underlined primary-color"
                                    onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
                                  >
                                    {__('our terms and conditions')}
                                  </span>{' '}
                                  {__('and')}{' '}
                                  <span
                                    className="primary-color  pointer underlined"
                                    onClick={() =>
                                      forwardTo('/privacy', { fromRegisterPage: true })
                                    }
                                  >
                                    {__('privacy policy')}
                                  </span>
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                          <FieldError
                            className="field-error"
                            value={__(this.state.formErrors.accepted_terms_and_conditions)}
                          />
                        </div>
                      </div>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="primary"
                                checked={is_subscribed}
                                onIonChange={(e) =>
                                  this.handleInput('is_subscribed', e.detail.checked)
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__(
                                    "Opt-in to receive updates, offers and promotions via email",
                                  )}
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                        </div>
                      </div>
                    </IonList>
                  </div>
                  <Spacer />
                  <IonButton
                    expand="block"
                    color="primary"
                    className="register-button uppercase okx-font-secondary"
                    onClick={() => this.handleRegister()}
                  >
                    {__('Sign up')}
                  </IonButton>
                </>
              ) : (
                <>
                  <div className="no-padding">
                    <IonList>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <StrongText>{__('Terms & conditions')}</StrongText>
                            <Spacer size={1} />
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="primary"
                                checked={accepted_terms_and_conditions}
                                onIonChange={(e) =>
                                  this.handleInput(
                                    'accepted_terms_and_conditions',
                                    e.detail.checked,
                                  )
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__('By signing up you agree to') + ' '}{' '}
                                  <span
                                    className="pointer underlined primary-color"
                                    onClick={() => forwardTo('/terms', { fromRegisterPage: true })}
                                  >
                                    {__('our terms and conditions')}
                                  </span>{' '}
                                  {__('and')}{' '}
                                  <span
                                    className="primary-color  pointer underlined"
                                    onClick={() =>
                                      forwardTo('/privacy', { fromRegisterPage: true })
                                    }
                                  >
                                    {__('privacy policy')}
                                  </span>
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                          <FieldError
                            className="field-error"
                            value={__(this.state.formErrors.accepted_terms_and_conditions)}
                          />
                        </div>
                      </div>
                      <div lines="none">
                        <div tabIndex="-1"></div>
                        <div className="toggle">
                          <IonLabel>
                            <div className="register-terms-wrapper">
                              <IonCheckbox
                                color="primary"
                                checked={is_subscribed}
                                onIonChange={(e) =>
                                  this.handleInput('is_subscribed', e.detail.checked)
                                }
                              />
                              <IonLabel className="ion-text-wrap">
                                <NormalText color="primary">
                                  {__(
                                    "I'd like to receive email updates that contain news, offers and promotions",
                                  )}
                                </NormalText>
                              </IonLabel>
                            </div>
                          </IonLabel>
                        </div>
                      </div>
                    </IonList>
                  </div>
                  <div className="top-medium">
                    {hasSocialLogin && (
                      <>
                        <SocialLogin
                          isRegister={true}
                          validateForm={() => this.validateSocialForm()}
                        />
                        <Spacer size={1} />
                        <div className="block-separator">
                          <NormalText>{__('or')}</NormalText>
                        </div>
                        <Spacer size={1} />
                      </>
                    )}
                    <IonButton
                      expand="block"
                      color="secondary"
                      fill="clear"
                      className="link underlined"
                      onClick={() => this.setState({ registerWithEmail: true })}
                    >
                      {__('Sign up with email')}
                    </IonButton>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="absolute-content flex-row-wrapper">
              <Title>{__('About you')}</Title>
            
              <Spacer size={2} />
              <IonList>
                
                <IonItem lines="none" className="input-field-wrapper">
                  <IonInput
                    placeholder={__('Mobile number')}
                    onIonInput={(e) => this.handleInput('mobile', e.target.value)}
                    required={true}
                    type="tel"
                    pattern="tel"
                    inputmode="tel"
                    onKeyPress={(e) => {
                      const reqexFormat = mobile ? '^[0-9]+$' : '^[+0-9]+$';
                      let regex = new RegExp(reqexFormat);
                      if (regex.test(e.key)) {
                        return true;
                      }
                      e.preventDefault();
                      return false;
                    }}
                    value={mobile}
                  ></IonInput>
                </IonItem>
                <Spacer size={1} />
                {!getConfig().restrictions?.min_registration_age && (
                  <IonItem lines="none" className="input-field-wrapper">
                    <DatePicker
                      placeholder="Date of birth"
                      className="data-picker-input"
                      display="bottom"
                      max={yesterday}
                      defaultValue={defaultDateValue}
                      setText={__('Done')}
                      cancelText={__('Cancel')}
                      value={birthday}
                      onSet={(e, a) => this.handleInput('birthday', a.element.value)}
                      dateFormat="dd/mm/yy"
                    />
                  </IonItem>
                )}
              </IonList>
              <Spacer size={1} />

              <div className="flex-min">
                <IonButton
                  expand="block"
                  color="primary"
                  onClick={() => {
                    const profile = {
                      first_name,
                      last_name,
                      mobile,
                      birthday,
                      is_subscribed,
                    };
                    this.props.dispatch(updateProfile(profile));
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Continue')}
                </IonButton>
                <IonButton
                  expand="block"
                  fill="clear"
                  color="secondary"
                  className=" underlined link transparent"
                  onClick={() => {
                    forwardTo(getDefaultRoute(this.props.navConfig).path, {
                      fromLogin: this.state.fromGiftVoucher,
                    });
                    dispatch({
                      type: CLEAR_REGISTER_FORM,
                    });
                  }}
                >
                  {__('Skip for now')}
                </IonButton>
              </div>
            </div>
          )}
        </Layout>
        <IonAlert
          isOpen={isRegisterModalOpen}
          onDidDismiss={() => this.props.dispatch(setModal('isRegisterModalOpen', false))}
          header={__('Success')}
          message={__('Register processed. Please check your mail')}
          buttons={[
            {
              text: __('Close'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => this.props.dispatch(setModal(('isRegisterModalOpen', false))),
            },
          ]}
        />
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { auth, isRegisterModalOpen, registerFormData } = state.profile;
  const { navConfig } = state.common;
  return {
    auth,
    isRegisterModalOpen,
    registerFormData,
    navConfig,
  };
};

export default connect(stateToProps)(withTranslation(Register));
