import { IonButton, IonIcon, IonInput, IonItem } from '@ionic/react';
import { chevronDownOutline, chevronForwardOutline, pencilOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, Sectiontitle, SmallText, Spacer, StrongText, Subtitle, Title } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Layout from '../../components/layout';
import Modal from '../../components/modal';
import OrderContent from '../../components/orderContent';
import { PillGroup } from '../../components/pill';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { forwardTo, go, goBack, isEmptyObject, isWebConfig, validateProfileData } from '../../lib/utils';
import { ApplyPoints } from '../../screens/applyPoints';
import { restoreAuth, sendVoucherCode, setModal, setProtectedReferrer } from '../../store/actions';
import './index.css';
import MobileInput from '../../components/mobileInput';
import discountIcon from '../../assets/images/icons/discount.svg'
import VoucherCodeInput from '../../components/voucherCodeInput';
import { Capacitor } from '@capacitor/core';
import CardList from '../../components/cardList';
import CheckoutPay from '../checkout/CheckoutPay';
const isWeb = () => Capacitor.getPlatform() === 'web';

const {
	getRestauranName,
	getOrderDate,
	getOrderTime,
	changeItemQuantity,
	itemsCount,
	getItems,
	setMobile,
	getMobile,
	getAllergen,
	getTotal,
	isMinimumOrderTotalSatisfied,
	getTableNumber,
	getASAP,
	getSelectedCard
} = Basket;

class OrderSummaryRaw extends React.Component {
	constructor(props) {
		super(props);
		const profile = this.props.profile;
		if (!getMobile() && profile && profile.mobile) {
			setMobile(profile.mobile);
		}
		this.state = {
			quantityModal: null,
			allergenModal: { isOpen: false, fromCheckoutBtn: false },
			applyPointsModalOpen: false,
			mobile: getMobile() || '',
			email: profile && !profile?.is_guest && profile.email ? profile.email : '',
			customFieldValue: Basket.getCustomFieldValue()?.field_value || '',
			showDiscount: false,
			canAppleORGooglePay: false,

			mobile_code: this.props.profile.mobile_code ? this.props.profile.mobile_code : null,
			mobile_value: this.props.profile.mobile_value
				? this.props.profile.mobile_value
				: this.props.profile.mobile == this.props.profile.mobile_code
					? ''
					: this.props.profile.mobile,
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.mobile !== prevProps.profile.mobile) {
			this.setState({ mobile: this.props.profile.mobile });
		}
	}

	handleInput = (key, val) => {
		this.setState({ [key]: val });
		setMobile(val);
	};

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2);
		} else {
			goBack();
		}
	};

	handleSubmit = (allergens = []) => {
		const { cards } = this.props;
		const { email, mobile } = this.state;
		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (!isMinimumOrderTotalSatisfied()) {
			// display toast with flag 'true'
			isMinimumOrderTotalSatisfied(true);
			return;
		}
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: true } });
			return;
		}
		Basket.setCustomFieldValue({
			field_value: this.state.customFieldValue,
			field_name: this.state.customFieldName,
		});
		const uuid = `${this.props.profile.id}_${Date.now()}`;
		Basket.setUUID(uuid);
		if (getTotal() === 0) {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? Basket.createOrder() : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details', { email, mobile }) : Basket.createOrder();
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					Basket.createOrder();
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}

			return;
		}
		if (cards && cards.length >= 1) {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? forwardTo('/checkout') : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details', { email, mobile }) : forwardTo('/checkout');
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					forwardTo('/checkout', {
						giftVoucher: Basket.getDeliveryOption().id === 'gift-vouchers' ? this.props.giftVoucherData : false,
					});
					setMobile(this.props.profile.mobile);
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}
		} else {
			if (Basket.getOrderType() === 'Table') {
				const tableRequiredFields = getConfig().general.tableRequiredFields || [];
				if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
					this.props.auth.loggedIn ? forwardTo('/checkout') : forwardTo('/contact-details', { email, mobile });
				} else {
					let valid = true;
					for (let i = 0; i < tableRequiredFields.length; i++) {
						if (!this.props.profile[`${tableRequiredFields[i]}`]) {
							valid = false;
							break;
						}
					}
					!valid ? forwardTo('/contact-details') : forwardTo('/checkout');
				}
			} else {
				if (this.props.profile.mobile && this.props.profile.first_name) {
					setMobile(this.props.profile.mobile);
					forwardTo('/checkout', {
						giftVoucher: Basket.getDeliveryOption().id === 'gift-vouchers' ? this.props.giftVoucherData : false,
					});
				} else {
					forwardTo('/contact-details', { email, mobile });
				}
			}
		}
	};

	handleOrderItemClick = (item, index, quantity) => {
		changeItemQuantity(index, quantity);
		// this.setState({ quantityModal: { item, index } });
	};

	updateItemQuantity = () => {
		const { item, index } = this.state.quantityModal;
		if (this.state.quantityModal) {
			changeItemQuantity(index, item.quantity);
			this.setState({ quantityModal: null });
		}
	};

	onIncrementerUpdate = (newQuantity) => {
		this.setState({
			quantityModal: {
				...this.state.quantityModal,
				item: {
					...this.state.quantityModal,
					quantity: newQuantity,
				},
			},
		});
	};

	formatDayName = (__, name) => {
		if (name.includes('Today')) {
			name = 'Today'.toLowerCase();
		} else if (name.includes('Tomorrow')) {
			name = 'Tomorrow'.toLowerCase();
		} else {
			name = getOrderDate();
		}
		return `${__(name)}`;
	};
	isRedeemPointsDisabled = () => {
		const { redeemPointsMin } = getConfig().general;
		const { available_balance } = this.props.profile;
		return available_balance < redeemPointsMin ? true : false;
	};

	findSelectedSlot = (dayInWeek, time) => {
		const restaurant = Basket.getRestaurant();
		const selectedDay =
			!isEmptyObject(restaurant) &&
				restaurant.delivery_times_json &&
				!isEmptyObject(restaurant.delivery_times_json) &&
				restaurant.delivery_times_json.slots &&
				!isEmptyObject(restaurant.delivery_times_json.slots)
				? restaurant.delivery_times_json.slots[dayInWeek]
				: null;
		if (selectedDay) {
			const selectedSlot = selectedDay.find((day) => moment(day.start_time, 'HH:mm').format('HH:mm') === moment(time, 'HH:mm a').format('HH:mm'));
			return moment(selectedSlot.start_time, 'HH:mm').format('h:mm a') + ' - ' + moment(selectedSlot.end_time, 'HH:mm').format('h:mm a');
		}
		return null;
	};

	drawContentHeader = (__, deliveryOption, orderType, deliveryAddress) => {
		let dateName = this.formatDayName(
			__,
			moment(Basket.getOrderDate(), 'dddd Do MMMM').calendar(null, {
				sameDay: '[Today]',
				nextDay: '[Tomorrow]',
			}),
		);
		if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-point') {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-at-counter') {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>{' '}
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'charter-delivery' && !isEmptyObject(deliveryAddress)) {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
					<div>
						<NormalText>{__('Delivery to')}</NormalText>
					</div>
					<StrongText>
						{deliveryAddress.addressLine1}, {deliveryAddress.place} {deliveryAddress.postalCode} {dateName} {__('at')} {Basket.getOrderTime()}
					</StrongText>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'table') {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>{' '}
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
					<div>
						<NormalText>{__('Table number')}</NormalText>
						<StrongText className="bold">{Basket.getTableNumber()}</StrongText>
					</div>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'scheduled-collection') {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>{' '}
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
					<div>
						<NormalText>{__('Collection time')}</NormalText>
					</div>
					<StrongText className="bold">
						{dateName} {'at'} {Basket.getOrderTime('h:mm a')}
					</StrongText>
				</div>
			);
		} else if (!isEmptyObject(deliveryOption) && deliveryOption.id === 'pick-up-at-counter') {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>{' '}
						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
				</div>
			);
		} else {
			return (
				<div className="box-content order-header-content">
					<div>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							stroke="var(--ion-color-primary)"
							className="icon icon-tabler icon-tabler-map"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="3 7 9 4 15 7 21 4 21 17 15 20 9 17 3 20 3 7"></polyline>
							<line x1="9" y1="4" x2="9" y2="17"></line>
							<line x1="15" y1="7" x2="15" y2="20"></line>
						</svg>

						<Subtitle>{Basket.getRestauranName()}</Subtitle>
					</div>
					<div>
						<NormalText>{__('Collection time')}</NormalText>
						<StrongText className="bold">{Basket.getOrderTime('h:mm a')}</StrongText>
					</div>
				</div>
			);
		}
	};
	handleApplyModal = (flag) => {
		const { history, auth } = this.props;
		const isAuth = auth.loggedIn;
		if (!isAuth) {
			this.props.dispatch(setProtectedReferrer(history.location.path));
			this.props.dispatch(restoreAuth());
			forwardTo('/login');
		} else {
			if (isWebConfig()) {
				this.setState({ applyPointsModalOpen: flag });
			} else {
				forwardTo('/apply-points');
			}
		}
	};
	handleApplyLoyaltyModal = () => {
		const { history, auth } = this.props;
		const isAuth = auth.loggedIn;
		if (!isAuth) {
			this.props.dispatch(setProtectedReferrer(history.location.path));
			this.props.dispatch(restoreAuth());
			forwardTo('/login');
		} else {
			forwardTo('/apply-loyalty');
		}
	};
	formatTableNumberForSelect = (selectedRestaurantId) => {
		const { __ } = this.props;
		let arrForSelect = [];
		if (selectedRestaurantId) {
			(this.state.totalTableNumbers || []).map((table, index) => {
				arrForSelect.push({ text: table, value: table });
			});
		}
		return [{ text: __('Select table'), value: null }, ...arrForSelect];
	};
	getCustomFieldForOrderType = () => {
		const custom_field = Basket.getRestaurant()?.custom_field;
		let result = false;
		switch (Basket.order_type) {
			case 'collection':
				result = custom_field?.['collection'];
				break;
			case 'scheduled-collection':
				result = custom_field?.['scheduled-collection'];
				break;
			case 'table':
				result = custom_field?.['table'];
				break;
			case 'charter-delivery':
				result = custom_field?.['charter-delivery'];
				break;
			case 'pick-up-at-counter':
				result = custom_field?.['pick_up_at_counter'];
				break;
		}
		if (isEmptyObject(result)) {
			return false;
		} else {
			return result;
		}
	};
	setMobileCode = (val) => {
		this.setState({ mobile_code: val, mobile: `${val}${this.state.mobile_value}` });
	};
	setMobileValue = (val) => {
		this.handleInput('mobile_value', val);
	};

	handleNativeSubmit = (allergens = []) => {
		const { email, mobile } = this.state;
		const force_allergen = getConfig()?.flags ? getConfig().flags.force_allergen : false;
		if (!isMinimumOrderTotalSatisfied()) {
			isMinimumOrderTotalSatisfied(true);
			return;
		}
		if (allergens.length > 0 && force_allergen) {
			this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: true } });
			return;
		}
		Basket.setCustomFieldValue({
			field_value: this.state.customFieldValue,
			field_name: this.state.customFieldName,
		});
		const uuid = `${this.props.profile.id}_${Date.now()}`;
		Basket.setUUID(uuid);
		if (Basket.getOrderType() === 'Table') {
			const tableRequiredFields = getConfig().general.tableRequiredFields || [];
			if (tableRequiredFields?.length === 0 || !tableRequiredFields) {
				this.props.auth.loggedIn ? Basket.createOrder() : forwardTo('/contact-details', { email, mobile });
			} else {
				let valid = true;
				for (let i = 0; i < tableRequiredFields.length; i++) {
					if (!this.props.profile[`${tableRequiredFields[i]}`]) {
						valid = false;
						break;
					}
				}
				!valid ? forwardTo('/contact-details', { email, mobile }) : Basket.createOrder('NativePayment');
			}
		} else {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				Basket.createOrder('NativePayment');
			} else {
				forwardTo('/contact-details', { email, mobile });
			}
		}
	};

	handlerOfVoucherCode = (e) => {
		const val = e.target.value.toUpperCase();
		this.setState({ voucherCode: val });
	};
	sendCode = () => {
		const { dispatch } = this.props;
		const { voucherCode } = this.state;
		const data = { code: voucherCode.toUpperCase() };
		this.setState({ voucherCode: null });
		dispatch(sendVoucherCode(data, false, true));
	};

	render() {
		const { __, profile, auth, dispatch, cards, vouchers, clientSecret } = this.props;
		const { quantityModal, allergenModal, applyPointsModalOpen, mobile, email, showDiscount, mobile_code, mobile_value, canAppleORGooglePay } = this.state;
		const valid = validateProfileData(profile).isValid;
		const allergens = getAllergen() || [];
		const isAuth = auth.loggedIn;
		const customFieldData = this.getCustomFieldForOrderType();
		const isGuest = isEmptyObject(profile) || profile?.is_guest;
		const numberOfApplicableVouchers = vouchers?.length || 0;
		const applicableVouchersText = numberOfApplicableVouchers === 1 ? `${numberOfApplicableVouchers} ${__('voucher available')}` : `${numberOfApplicableVouchers} ${__('vouchers available')}`;
		return (
			<>
				<div className="absolute-content scrollable-y  summary-wrapper">
					{Basket.getDeliveryOption().id !== 'gift-vouchers' ? (
						<>
							<div className="scrollable-y checkout">
								<Subtitle>{__('Collection details')}</Subtitle>
								{this.drawContentHeader(__, Basket.getDeliveryOption(), Basket.getOrderType(), Basket.getDeliveryAddress())}
								<Spacer size={1} />
								<div className="input-field-container">
									<Subtitle>{__('Confirm contact number')}</Subtitle>
									<MobileInput required={true} mobile_code={mobile_code} mobile_value={mobile_value} setMobileCode={this.setMobileCode} setMobileValue={this.setMobileValue} className='box-content' />

								</div>
								{Basket.getDeliveryOption().id === 'pick-up-at-counter' && (
									<>
										<div className="input-field-container ">
											<NormalText>{__('Enter your email address')}</NormalText>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													value={email}
													clearInput={true}
													disabled={!isGuest}
													type="email"
													pattern="email"
													inputmode="email"
													onIonInput={(e) => this.setState({ email: e.target.value })}
												>
													{email === '' ? <IonIcon icon={pencilOutline} className="contact-number-icon" /> : null}
												</IonInput>
											</IonItem>
										</div>
									</>
								)}
								{customFieldData && (
									<>
										<div className="input-field-container ">
											<NormalText>{__(customFieldData.field_name)}</NormalText>
											<IonItem lines="none" className="input-field-wrapper">
												<IonInput
													value={this.state.customFieldValue}
													onIonInput={(e) =>
														this.setState({
															customFieldValue: e.target.value,
															customFieldName: customFieldData.field_name,
														})
													}
												></IonInput>
											</IonItem>
										</div>
										{this.state.customFieldError && <FieldError className="field-error" value={__('This field is required')} />}
									</>
								)}
								<Spacer size={1} />

								{itemsCount() > 0 ? (
									<OrderContent showAddItems={true} handleOrderItemClick={this.handleOrderItemClick.bind(this)} />
								) : (
									<>
										<IonItem lines="none">
											<div className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>
												{__('No items')}
											</div>
										</IonItem>
										<IonButton color="primary" expand="block" onClick={() => forwardTo('/order')}>
											{__('Add more items')}
										</IonButton>
									</>
								)}

								<Spacer size={1} />
								<div className="order-summary-additional-buttons-wrapper">
									{Basket.getDeliveryOption().id !== 'gift-vouchers' ?
										showDiscount ?
											(
												<>
													<IonButton
														disabled={numberOfApplicableVouchers == 0}
														fill="clear"
														expand="block"
														className="order-summary-additional-button box-content"
														color="dark"
														onClick={() => forwardTo('/apply-loyalty')}
													>
														<IonIcon icon={discountIcon} className='discount-icon' />
														<NormalText>{applicableVouchersText}</NormalText>
														<IonIcon icon={chevronForwardOutline}></IonIcon>
													</IonButton>
													<VoucherCodeInput autoApplyVoucher={true} useCustomButton={true}>
														<IonButton
															fill="clear"
															expand="block"
															VoucherCodeInput className="order-summary-additional-button box-content"
															color="dark"
														>
															<IonIcon icon={discountIcon} className='discount-icon' />
															<NormalText> {__('Apply voucher code')}</NormalText>
															<IonIcon icon={chevronForwardOutline}></IonIcon>
														</IonButton>
													</VoucherCodeInput>
												</>
											) : (
												<>
													<IonButton
														onClick={() => this.setState({ showDiscount: true })}
														className={`${itemsCount() === 0 || !valid ? 'disabled' : ''}`}
														expand="block"
														color="primary"
														fill="outline"
														disabled={Basket.getAppliedPoints() > 0 || Basket.getAppliedVoucher().length > 0 || itemsCount() == 0}
													>
														{__('Apply discount or loyalty vouchers')}
													</IonButton>
													<Spacer size={1} />
												</>
											) : null
									}

									{allergens.length > 0 ? (
										<IonButton
											fill="clear"
											expand="block"
											className="order-summary-additional-button"
											color="dark"
											onClick={() => this.setState({ allergenModal: { isOpen: true, fromCheckoutBtn: false } })}
										>
											<NormalText> {__('View allergen information')}</NormalText>
											<IonIcon icon={chevronForwardOutline}></IonIcon>
										</IonButton>
									) : null}
									{/* {Basket.getDeliveryOption().id !== 'gift-vouchers' && (
										<>
											<IonButton
												onClick={() => {
													!isAuth ? forwardTo('/login') : itemsCount() === 0 || !valid ? dispatch(setModal('isVerfiedModalOpen', true)) : this.handleApplyLoyaltyModal(true);
												}}
												className={`${itemsCount() === 0 || !valid ? 'disabled' : ''} order-summary-additional-button`}
												expand="block"
												color="primary"
												fill="clear"
												disabled={Basket.getAppliedPoints() > 0 || Basket.getAppliedVoucher().length > 0 || itemsCount() == 0}
											>
												<NormalText>{__('Redeem loyalty rewards')}</NormalText>
												<IonIcon icon={chevronForwardOutline}></IonIcon>
											</IonButton>
										</>
									)} */}

									{getConfig().flags.hasApplyVoucherCodeOnCheckout && (
										<>
											<IonButton
												onClick={() => {
													!isAuth
														? forwardTo('/login')
														: itemsCount() === 0 || !valid
															? dispatch(setModal('isVerfiedModalOpen', true))
															: this.setState({ isVoucherCodeInputVisible: !isVoucherCodeInputVisible });
												}}
												className={`order-summary-additional-button`}
												expand="block"
												color="primary"
												fill="clear"
												disabled={itemsCount() === 0}
												{...(isVoucherCodeInputVisible && { style: { border: 'none' } })}
											>
												<NormalText> {__('Apply voucher code')}</NormalText>
												<IonIcon icon={isVoucherCodeInputVisible ? chevronDownOutline : chevronForwardOutline}></IonIcon>
											</IonButton>
											{isVoucherCodeInputVisible && (
												<div className="redeem-voucher-field-wrapper">
													<div className="input-field-container">
														<IonItem className="input-field-wrapper" lines="none">
															<IonInput placeholder={__('Voucher Code')} onIonInput={(e) => this.handlerOfVoucherCode(e)}></IonInput>
														</IonItem>
													</div>
													<IonButton onClick={this.sendCode} disabled={!voucherCode} color="primary" expand="block" className="submit-button">
														{__('Submit')}
													</IonButton>
												</div>
											)}
										</>
									)}
								</div>
							</div>
							<Spacer size={1} />
						</>
					) : (
						<div>
							<Title>{__('eGift Voucher Summary')}</Title>
							<Spacer size={1} />
							<div className="gift-voucher-order-content-summary box-content" style={{ margin: 0 }}>
								<Subtitle className="bold">
									{Basket.formatPrice(this.props.giftVoucherData?.points_value / 100)} {__('eGift Voucher')}
								</Subtitle>
								<NormalText>
									{__('Recipient')}
									{': '}
									{this.props.giftVoucherData?.recipient_email}
								</NormalText>
								{this.props.giftVoucherData?.send_on_date && (
									<NormalText className="block">
										{__('Send date')}
										{': '}
										{moment(this.props.giftVoucherData?.send_on_date).format('Do MMM yyyy')}
									</NormalText>
								)}
							</div>
							<Spacer size={1} />

							<OrderContent showAddItems={false} />
						</div>
					)}
					{/* <IonButton
			  className={`redeem-points-btn ${
				itemsCount() === 0 || !valid ? 'disabled' : ''
			  } uppercase okx-font-secondary`}
			  disabled={this.isRedeemPointsDisabled()}
			  onClick={() => {
				if ((itemsCount() === 0 || !valid) && isAuth) {
				  dispatch(setModal('isVerfiedModalOpen', true));
				} else {
				  this.handleApplyModal(true);
				}
			  }}
			  expand="block"
			  fill="outline"
			  color="dark"
			>
			  {__('Redeem Loyalty')}
			</IonButton> */}



					<div className="flex-min pay-buttons-wrapper">
						{getConfig().frenchDisclaimer?.disclaimerImage && (
							<div className="disclaimer-wrapper">
								<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
							</div>
						)}
						{getTotal() > 0 ? <>

							{isWeb() ? (
								<div className='pay-buttons-wrapper'>
									{!canAppleORGooglePay && cards.length === 0 ? null : (<Subtitle className="bold">{__('Payment')}</Subtitle>)}
									<Spacer size={1} />
									{isAuth ? (
										<CheckoutPay isBillPay={false} clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} profile={profile} canMakePayment={canAppleORGooglePay} showBlockSeparator={true} />
									) : null}

									<CardList />
								</div>
							) : null
							}
							{isWeb() && cards.length > 0 ? (
								<IonButton
									disabled={itemsCount() === 0 || cards.length === 0 || !getSelectedCard() || !mobile_value}
									onClick={() => {
										if (customFieldData && customFieldData.is_required && this.state.customFieldValue == '') {
											this.setState({ customFieldError: true });
										} else {
											!this.props.location?.state?.fromTablePay ? Basket.createOrder() : Basket.createBillPay();
										}
									}}
									expand="block"
									className={'checkout-btn ' + (!isMinimumOrderTotalSatisfied() ? 'greyed' : '') + ' uppercase okx-font-secondary'}
								>
									{__('Pay with selected card')}
								</IonButton>
							) : !isWeb() && getConfig().nativePayment?.isVisible ? (
								<IonButton
									disabled={itemsCount() === 0}
									style={{ marginBottom: '10px' }}
									className="uppercase okx-font-secondary"
									onClick={() => {
										this.handleNativeSubmit(allergens);
									}}
									expand="block"
								>
									{__(getConfig().nativePayment?.label)}
								</IonButton>
							) : null}
							{(isWeb() || getConfig()?.prebuildPayment?.showOnNative) && getConfig().prebuildPayment?.isVisible ? (
								<IonButton
									style={{ marginBottom: '10px' }}
									className="uppercase okx-font-secondary"
									onClick={() => {
										Basket.createStripeOrder(getConfig().prebuildPayment?.label);
									}}
									expand="block"
								>
									{__(getConfig().prebuildPayment?.label)}
								</IonButton>
							) : null
							}
							{isWeb() && cards.length === 0 ? (
								<IonButton
									color="primary"
									expand="block"
									onClick={() => {
										this.handleSubmit();
									}}
								>
									{__('Pay')}
								</IonButton>
							) : isWeb() ? (
								<IonButton
									fill="clear"
									color="primary"
									expand='block'
									className="link underlined centered"
									onClick={() =>
										forwardTo('/card-add', { ...(this.props.location && this.props.location.state ? this.props.location.state : {}), addCardFromCheckout: true })
									}
								>
									{__((cards.length > 0 ? 'Or add another' : 'Add ') + ' payment card')}
								</IonButton>
							) : null
							}
						</> : <>
							<IonButton
								color="primary"
								expand="block"
								onClick={() =>
									this.handleSubmit()
								}
							>
								{__('Continue')}
							</IonButton>

						</>}
					</div>
				</div>

				<Modal
					cssClass="quantity-modal customIncrementModal"
					action={this.updateItemQuantity}
					actionLabel={__('Change')}
					isOpen={!!quantityModal}
					onDidDismiss={() => this.setState({ quantityModal: null })}
					showCancelButton={true}
				>
					<Title>{__('Change quantity')}</Title>
					<Spacer size={1} />
					{quantityModal && quantityModal.item ? <Incrementer allowNegative={false} quantity={quantityModal.item.quantity} onUpdate={this.onIncrementerUpdate} /> : null}
				</Modal>
				<Modal cssClass="allergen-modal" isOpen={allergenModal?.isOpen} onDidDismiss={() => this.setState({ allergenModal: { isOpen: false, fromCheckoutBtn: false } })}>
					<div className="flex-row-wrapper">
						<div className="scrollable-y rhino">
							<Title className="">{__('Allergen Information')}</Title>
							<NormalText>{__('Please speak to our staff if you have any concerns about allergies')}</NormalText>
							<Spacer />
							{getItems().map((item, index) => {
								let data = allergens.find((allergen) => allergen[1].sku === item.item.sku);
								const modifiers = item?.selectedChoices?.flat()?.map(modifier => allergens?.find((allergen) => allergen?.[1]?.sku === modifier?.sku))?.map(el => el?.[0]?.allergens).filter(Boolean)
								const modifierAllergens = modifiers.length>0 ? modifiers[0] : []
								if (data && data.length > 0) {
									return (
										<>
											<div className="box-content" key={'allergen-' + index}>
												<NormalText className="block">
													{item.quantity}x {item.item.productName}
												</NormalText>
												<Spacer size={1} />
												<PillGroup items={[...new Set([...data[0].allergens,...modifierAllergens])]} borderColor="primary" />
											</div>
											<Spacer size={1} />
										</>
									);
								}

								return null;
							})}
						</div>
						<Spacer />
						{allergenModal?.fromCheckoutBtn && (
							<IonButton disabled={itemsCount() === 0} onClick={() => this.handleSubmit([])} expand="block">
								{__('Accept')}
							</IonButton>
						)}
						<div className="flex-min centered">
							<IonButton fill="clear" className="link underlined" color="secondary" onClick={() => this.setState({ allergenModal: { isOpen: false, fromCheckoutBtn: false } })}>
								{__('Hide allergen information')}
							</IonButton>
						</div>
					</div>
				</Modal>
				<Modal cssClass="apply-points-modal" isOpen={applyPointsModalOpen} onDidDismiss={() => this.handleApplyModal(false)}>
					<ApplyPoints handleApplyModal={this.handleApplyModal} applyPointsModalOpen={applyPointsModalOpen} />
				</Modal>
			</>
		);
	}
}

const mapStateToProps = (store) => {
	const { basketUpdated, deliveryOption, giftVoucherData, clientSecret } = store.orders;
	const { auth, vouchers } = store.profile;
	return {
		basketUpdated,
		profile: store.profile.profile,
		cards: store.orders.cards || [],
		deliveryOption,
		itemAllergens: store.restaurants.itemAllergens,
		auth,
		giftVoucherData,
		vouchers
	};
};

export const OrderSummary = connect(mapStateToProps)(withRouter(withTranslation(OrderSummaryRaw)));

class OrderSummaryWrapped extends React.Component {
	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2);
		} else if (this.props.location && this.props.location.state && this.props.location.state.skipBackToThePreviousPage) {
			forwardTo('/order');
		} else {
			goBack();
		}
	};
	render() {
		const { __ } = this.props;
		return (
			<Loading transparent>
				<Layout color="transparent" title={__('Checkout')} headerWithTitle backHandler={this.backHandler} scrollY={false}>
					<OrderSummary />
				</Layout>
			</Loading>
		);
	}
}

export default withTranslation(OrderSummaryWrapped);
