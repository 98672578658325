import { IonAlert, IonButton, IonIcon, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import { removePaymentCard, updateProfile } from '../../store/actions';
import { cardOutline, removeCircleOutline } from 'ionicons/icons';
import { NormalText, StrongText } from '../../components/common';
import { isDefined, isWebConfig } from '../../lib/utils';
import { withTranslation } from '../../lib/translate';
import Basket from '../../lib/basket';
import { connect } from 'react-redux';
import React from 'react';
import './index.css';

class CardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      removeCardAlert: null,
      selectPaymentCard: this.props.profile.cardToken || null,
    };
  }
  componentDidMount() {
    if (this.props.profile && this.props.profile?.cardToken) {
      Basket.changeSelectedCard(this.props.profile?.cardToken);
      this.setState({ selectPaymentCard: this.props.profile.cardToken });
      
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
      this.setState({ selectPaymentCard: this.props.profile.cardToken });
    }
  }

  changeDefaultPaymentCard = (event) =>
    this.setState({ selectPaymentCard: event.detail.value }, () => {
      this.props.dispatch(updateProfile({ cardToken: event.detail.value }, true));
    });

  removePaymentCard = () => {
    const { __ } = this.props;
    this.props.dispatch(
      removePaymentCard(this.state.removeCardAlert, {
        __,
        cb: () => this.setState({ removeCardAlert: null }),
      }),
    );
  };
  changeSelectedPaymentCard = (cardId) =>
    this.setState({ selectPaymentCard: cardId }, () => {
      Basket.changeSelectedCard(cardId);
    });

  handleRemoveCardAlert = (cardId) => {
    if(cardId){
      this.setState({ removeCardAlert: cardId })
    }
  };

  render() {
    const { __, cards } = this.props;
    const { removeCardAlert } = this.state;
    return (
      <>
        <IonList lines="none">
          <IonRadioGroup  value={this.state.selectPaymentCard}>
            {cards.map((card) => {
              const { id, last4, brand, exp_month, exp_year, name } = card;
              return (
                <IonItem lines="none" className="cards-list-card box-content order-summary-card" key={id} >
                  <IonIcon slot="start" icon={cardOutline} className="card-icon" ></IonIcon>
                  <IonLabel className="ion-text-wrap">
													<NormalText>
															{__(brand)} - {exp_month}/{exp_year}
														</NormalText>
														<StrongText className="dark-color no-margin block">**** **** **** {last4}</StrongText>
													
													</IonLabel>
                  <IonButton onClick={() => this.handleRemoveCardAlert(id)}>
                    <IonIcon icon={removeCircleOutline} className="remove-card-icon" />
                  </IonButton>
                  
                  <IonRadio
                    color={isWebConfig() ? 'primary' : 'white'}
                    slot="start"
                    value={id}
                    onIonSelect={() => {
                      this.setState({ selectPaymentCard: id }, () => {
                        Basket.changeSelectedCard(id);
                      });
                    }}
                  />
                </IonItem>
              );
            })}
          </IonRadioGroup>
        </IonList>
        <IonAlert
          isOpen={isDefined(removeCardAlert)}
          onDidDismiss={() => this.handleRemoveCardAlert(null)}
          header={__('Confirm')}
          message={__('Do you you want to remove this card?')}
          buttons={[
            {
              text: __('Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: __('Remove'),
              handler: () => this.removePaymentCard(),
            },
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    cards: store.orders.cards || [],
    profile: store.profile.profile || {},
  };
};

export default connect(mapStateToProps)(withTranslation(CardList));
