import { IonAlert, IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonList, IonRadioGroup } from '@ionic/react';
import { card, cardOutline, removeCircleOutline, trashBinOutline } from 'ionicons/icons';
import React from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { forwardTo, isDefined } from '../../lib/utils';
import { removePaymentCard, updateProfile } from '../../store/actions';
import './index.css';

class Cards extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			removeCardAlert: null,
			selectPaymentCard: this.props.profile.cardToken || null,
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
			this.setState({ selectPaymentCard: this.props.profile.cardToken });
		}
	}

	changeDefaultPaymentCard = (event) =>
		this.setState({ selectPaymentCard: event.detail.value }, () => {
			this.props.dispatch(updateProfile({ cardToken: event.detail.value }, true));
		});

	removePaymentCard = () => {
		const { __ } = this.props;
		this.props.dispatch(
			removePaymentCard(this.state.removeCardAlert, {
				__,
				cb: () => this.setState({ removeCardAlert: null }),
			}),
		);
	};

	handleRemoveCardAlert = (cardId) => this.setState({ removeCardAlert: cardId });

	render() {
		const { __, cards } = this.props;
		const { removeCardAlert } = this.state;

		return (
			<Loading transparent>
				<Layout color="transparent" title={__('Your payment cards')} >
					<div className="flex-row-wrapper absolute-content">
						<div className="scrollable-y">
							<Title >{__('Your payment cards')}</Title>
							{/* <SmallText>{__('Manage your default payment card')}</SmallText> */}
							{/* <Spacer size={3} /> */}
							<IonList lines="none">
								<IonRadioGroup onIonChange={this.changeDefaultPaymentCard} value={this.state.selectPaymentCard}>
									{cards.length > 0 ? (
										cards.map((card) => {
											const { id, last4, brand, exp_month, exp_year, name } = card;
											return (
												<IonItem lines="none" className="cards-list-card box-content " key={id}>
													<div tabIndex="-1"></div>
													<div className="cards-list-card-image">
														<IonIcon icon={cardOutline}></IonIcon>
													</div>
													<IonLabel className="ion-text-wrap">
													<NormalText>
															{__(brand)} - {exp_month}/{exp_year}
														</NormalText>
														<StrongText className="dark-color no-margin block">**** **** **** {last4}</StrongText>
													
													</IonLabel>
													{/* <IonRadio slot="start" value={id} /> */}
													<IonButton onClick={() => this.handleRemoveCardAlert(id)} className="link">
														<IonIcon color='primary' icon={removeCircleOutline} />
													</IonButton>
												</IonItem>
											);
										})
									) : (
										<IonItem className="cards-list-card box-content no-cards-box">
											<div className="cards-list-card-image">
												<IonIcon icon={cardOutline}></IonIcon>
											</div>
											<IonLabel className="ion-text-wrap">
												<NormalText className="single-item">{__('No payment cards added')}</NormalText>
											</IonLabel>
										</IonItem>
									)}
								</IonRadioGroup>
							</IonList>

							<Spacer />

							<IonButton
								expand="block"
								color="primary"
								onClick={() =>
									forwardTo('/card-add', {
										...(this.props.location && this.props.location.state ? this.props.location.state : {}),
									})
								}
							>
								{__('Add a new payment card')}
							</IonButton>
						</div>
						{/*<div className="flex-min">
							<IonButton onClick={ () => forwardTo('/card-add', { ...this.props.location && this.props.location.state ? this.props.location.state : {}}) } expand="block">{ __('Add Payment Card') }</IonButton>
						</div>*/}
					</div>
				</Layout>
				<IonAlert
					isOpen={isDefined(removeCardAlert)}
					onDidDismiss={() => this.handleRemoveCardAlert(null)}
					header={__('Confirm')}
					message={__('Do you you want to remove this card?')}
					buttons={[
						{
							text: __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
						},
						{
							text: __('Remove'),
							handler: () => this.removePaymentCard(),
						},
					]}
				/>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		cards: store.orders.cards || [],
		profile: store.profile.profile || {},
	};
};

export default connect(mapStateToProps)(withTranslation(Cards));
