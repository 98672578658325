import { IonContent, IonRefresher, IonRefresherContent, IonToolbar, useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getConfig } from '../../appConfig';
import Header from '../../components/header';
import { getRouteClassName, isWebConfig } from '../../lib/utils';
import { Title } from '../common';
import StaticHeader from '../staticHeader';
import './index.css';

const defaultHeaderTitle = getConfig().general.clientName;

const Layout = ({
	history,
	children,
	headerTitle,
	showSecondToolbar,
	hideBack,
	color,
	poster,
	blank,
	noPadding,
	contentClassName,
	scrollY,
	backHandler,
	selectCategoryOnScroll,
	navConfig,
	showHamburger,
	headerIconColors,
	withRefresher,
	onRefreshHandler,
	headerWithTitle,
	clientProfile,
	profile,
	title,
	style,
	searchActive,
	onSearchHandler,
	hasSearchIcon
}) => {
	useIonViewWillEnter(() => {
		// eslint-disable-next-line no-console
		console.log('1. WillEnter event fired');
	});
	useIonViewDidEnter(() => {
		// eslint-disable-next-line no-console
		console.log('2. DidEnter event fired');
	});
	useIonViewWillLeave((a, b, c) => {
		// eslint-disable-next-line no-console
		console.log('3. WillLeave event fired', a, b, c);
	});
	useIonViewDidLeave((a, b, c) => {
		// eslint-disable-next-line no-console
		console.log('4. DidLeave event fired', a, b, c);
	});
	const routeClassName = getRouteClassName(history.location.pathname, navConfig);
	const logo = clientProfile.logo_image || '';

	return (
		<>
			{!profile.is_guest ? (
				<>
					{blank ? null : (
						<>
							{!showSecondToolbar ? (
								<StaticHeader
									hideBack={hideBack}
									backHandler={backHandler}
									showHamburger={showHamburger}
									headerIconColors={headerIconColors}
									headerWithTitle={headerWithTitle}
									title={title}
									onSearchHandler={onSearchHandler}
									searchActive={searchActive}
									hasSearchIcon={hasSearchIcon}
								/>
							) : (
								<>
									<StaticHeader
										hideBack={hideBack}
										backHandler={backHandler}
										headerIconColors={headerIconColors}
										headerWithTitle={headerWithTitle}
										title={title}
										onSearchHandler={onSearchHandler}
										searchActive={searchActive}
										hasSearchIcon={hasSearchIcon}
									/>
									<Header title={headerTitle || defaultHeaderTitle} hideBack={hideBack} backHandler={backHandler} />
								</>
							)}
						</>
					)}
				</>
			) : (
				<>
					{!isWebConfig() && (
						<IonToolbar
							color="transparent"
							className="primary-toolbar"
							style={{
								backgroundSize: 'auto 35px',
								backgroundImage: !isWebConfig() && !headerWithTitle ? `url(${logo})` : '',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'center',
							}}
						>
							{headerWithTitle && <Title className="centered">{title}</Title>}
						</IonToolbar>
					)}
				</>
			)}
			<IonContent scrollY={scrollY !== undefined ? scrollY : true} color={color || 'white'} className={routeClassName + (contentClassName ? ' ' + contentClassName : '')}>
				{withRefresher && !isWebConfig() && (
					<IonRefresher pullFactor="0.8" onIonRefresh={(e) => onRefreshHandler(e)} slot="fixed">
						<IonRefresherContent pullingIcon="chevron-down-circle-outline"></IonRefresherContent>
					</IonRefresher>
				)}
				{poster ? <div className={'poster ' + poster} /> : null}
				<div style={style} className={noPadding ? 'no-padding' : 'ion-padding'} onScroll={routeClassName === 'route-order' ? selectCategoryOnScroll : null}>
					{withRefresher && isWebConfig() && (
						<IonRefresher pullFactor="0.8" onIonRefresh={(e) => onRefreshHandler(e)} slot="fixed">
							<IonRefresherContent {...(!isWebConfig() ? { pullingIcon: 'chevron-down-circle-outline' } : {})}></IonRefresherContent>
						</IonRefresher>
					)}
					{children}
				</div>
			</IonContent>
		</>
	);
};

function mapStateToProps(state) {
	return {
		navConfig: state.common.navConfig,
		profile: state.profile.profile,
		clientProfile: state.common.clientProfile,
	};
}
export default connect(mapStateToProps)(withRouter(Layout));
