import { IonIcon, IonRippleEffect } from '@ionic/react';
import { globe, logoFacebook, logoInstagram, logoLinkedin, logoTwitter } from 'ionicons/icons';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, Subtitle, Title } from '../../components/common';
import Layout from '../../components/layout';
import NoData from '../../components/noData';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import { isDefined, isEmptyObject, openExternalLink, ucWords } from '../../lib/utils';
import { getSocials } from '../../store/actions';
import './index.css';
import headerImage from '../../assets/images/icons/social-header-icon.png';

class Social extends Component {
  componentDidMount() {
    this.props.dispatch(getSocials());
  }

  parseSocialItem(key = '', value = '') {
    let label = key;
    let icon = key;
    let link = value;
    let uppercasedKey = key
      .split('')
      .map((char, i) => (i === 0 ? char.toUpperCase() : char))
      .join('');
    switch (uppercasedKey) {
      case 'Facebook':
        icon = logoFacebook;
        break;
      case 'Twitter':
        icon = logoTwitter;
        break;
      case 'Instagram':
        icon = logoInstagram;
        break;
      case 'Linkedin':
        icon = logoLinkedin;
        break;
      default:
        icon = globe;
        break;
    }
    return {
      link,
      icon,
      label,
    };
  }

  render() {
    const { __, social } = this.props;
    return (
      <Loading>
        <Layout
          hideSecondToolbar={true}
          color="transparent"
          title={__('Social channels')}
        >
          <img className='block' style={{height:'40px'}} src={headerImage} />
          <Spacer size={1}/>
          <div className="header-wrapper">
            <Title>{__('Social channels')}</Title>

          </div>
          <Spacer size={1} />
          <div className="frm-wrapper">
            {isDefined(social) && !isEmptyObject(social) ? (
              <>
                {Object.keys(social || {}).map((key, index) => {
                  const value = social[key];
                  const { link, icon, label } = this.parseSocialItem(key, value);
                  const capitalizeLabel = ucWords(label);
                  return (
                    <div key={'soc-item-' + index}>
                      <div
                        className="square ion-activatable"
                        onClick={() => openExternalLink(link)}
                      >
                        <div className="soc-icon">
                          <IonIcon color="primary" icon={icon} />
                        </div>
                        <NormalText>{__(capitalizeLabel)}</NormalText>
                        <IonRippleEffect></IonRippleEffect>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <NoData label={__('No social networks')} />
            )}
          </div>
        </Layout>
      </Loading>
    );
  }
}

const stateToProps = (state) => {
  const { social } = state.common;
  return {
    social,
  };
};

export default connect(stateToProps)(withTranslation(Social));
