import React from 'react';
import { IonHeader, IonContent, IonIcon, IonToolbar, IonButtons, IonButton } from '@ionic/react';
import { menuOutline } from 'ionicons/icons';

const SmallDrawer = ({ toggleMenu, logoColor }) => {
  return (
    <>
      <IonHeader className="small-drawer-header">
        <IonToolbar>
          <IonButtons>
            <IonButton button onClick={() => toggleMenu()}>
              <IonIcon mode="ios" icon={menuOutline} color='tertiary' />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent></IonContent>
    </>
  );
};

export default SmallDrawer;
