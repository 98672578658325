import React from 'react';
import { withRouter } from 'react-router';
import { IonButton, isPlatform } from '@ionic/react';
import { getConfig } from '../../appConfig';
import { connect } from 'react-redux';
import { withTranslation } from '../../lib/translate';
import { addToAppleWallet, addToWallet } from '../../store/actions';
import googleWalletIcon from '../../assets/images/google-wallet-button.png';
import appleWalletIcon from '../../assets/images/apple-wallet.png';

class AddToWallet extends React.Component {
  onClickHandler = () => {
    const { client, defaultGoogleLoyaltyObject } = getConfig();
    const { profile } = this.props;
    const loyaltyObject = {
      classId: client.client_id,
      ...defaultGoogleLoyaltyObject,
      email: this.props.profile.email,
      accountName: `${profile.first_name} ${profile.last_name}`,
      barcode: {
        ...defaultGoogleLoyaltyObject.barcode,
        type: 'QR_CODE',
        value: profile.qr_code,
      },
    };
    this.props.dispatch(addToWallet(loyaltyObject));
  };

  createApplePass() {
    const passkitConfig = getConfig().passkitConfig || {};
    const currentTime = new Date().getTime();
    const passName = currentTime + '_' + new Date().toISOString().split('T')[0].replace(/-/gi, '');
    const appleWalletdata = {
      ...passkitConfig,
      name: this.props.profile?.first_name,
      lastName: this.props.profile?.last_name,
      brand: this.props.clientProfile?.buisiness_name,
      qr: this.props.profile?.qr_code,
      bgColor: passkitConfig?.bgColor || 'rgb(200,225,251)',
      color: passkitConfig?.color || 'rgb(0,0,0)',
      passName: passName,
    };
    this.props.dispatch(addToAppleWallet(appleWalletdata));
  }

  formatAppleWalletUrl = () => {
		const nativeWallet = getConfig().api.nativeWallet;
		const passkitConfig = getConfig().passkitConfig || {};
    const currentTime = new Date().getTime();
    const passName = currentTime + '_' + new Date().toISOString().split('T')[0].replace(/-/gi, '');
		const urlObject = {
		  ...passkitConfig,
		  name: this.props.profile?.first_name,
      lastName: this.props.profile?.last_name,
      brand: this.props.clientProfile?.buisiness_name,
      qr: this.props.profile?.qr_code,
      bgColor: passkitConfig?.bgColor || 'rgb(200,225,251)',
      color: passkitConfig?.color || 'rgb(0,0,0)',
      passName: passName,
		};
		const urlArray = [];
		Object.keys(urlObject).forEach((key) => {
		  if (!urlObject[key]) delete urlObject[key];
		});
		for (var p in urlObject)
		  if (urlObject.hasOwnProperty(p)) {
			urlArray.push(`${encodeURIComponent(p)}=${encodeURIComponent(urlObject[p])}`);
		  }
		urlArray.push('target="_system"');
		return `${nativeWallet}/apple-wallet?${urlArray.join('&')}`;
	};

  render() {
    const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
    if (hasAddToAppleWallet && isPlatform('ios')) {
      return (
        <a className="wallet-button" href={this.formatAppleWalletUrl()} download="">
					<img src={appleWalletIcon} />
				</a>
      );
    } else if (hasAddToGoogleWallet && isPlatform('android')) {
      return (
        <IonButton className="wallet-button" onClick={() => this.onClickHandler()}>
          <img src={googleWalletIcon} />
        </IonButton>
      );
    }
    return null;
  }
}

const stateToProps = (state) => {
  const { profile } = state.profile;
  const { clientProfile } = state.common;
  return {
    profile: profile,
    clientProfile: clientProfile,
  };
};

export default connect(stateToProps)(withRouter(withTranslation(AddToWallet)));
